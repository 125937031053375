import React, { useState } from "react";
import cornerLogoBlack from "../static/icons/cornerLogoOnly.svg";
import arrowRight from "../static/icons/arrowRight.svg";
import lineRight from "../static/lineRight.svg";
import lineLeft from "../static/lineLeft.svg";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { ModalWait } from "../components/ModalWait/ModalWait";
import planetCrypto from "../static/video/planetCrypto.mp4"

export const WaitList = () => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);

  const handleClose = () => {
    setShowModal(false);
    setStep(0);
  };

  return (
    <div
      className="h-100 container mx-auto d-flex flex-column align-items-center position-relative"
      style={{ minHeight: "100vh" }}
    >
      <Navbar
        bg="transparent"
        expand="lg"
        className="wait__nav w-100 d-flex justify-content-between align-items-center"
      >
        <Link
          to="/"
          className="wait__logo text-decoration-none text-dark fw-bold d-flex align-items-center"
        >
          <img
            src={cornerLogoBlack}
            alt="cornerSwapLogo"
            style={{
              height: "100%",
            }}
          />

          <span
            className="fs-6"
            style={{
              fontWeight: "800",
            }}
          >
            CORNERSWAP
          </span>
        </Link>

        <button
          className="d-none d-md-flex text-decoration-none bg-dark text-light fs-5 align-items-center px-5 rounded-3 mt-4 mt-sm-0 me-auto me-md-1 mx-auto mx-md-0"
          style={{
            boxShadow: "4px 5px 10px rgba(0, 0, 0, 0.5)",
            height: "56px",
          }}
          onClick={() => setShowModal(true)}
        >
          Join the waitlist
        </button>
      </Navbar>

      <p
        className="text-center col-9 col-md-10 p-0 m-0 mt-3 mt-md-0 mx-auto lh-sm wait__title--less"
        style={{
          fontWeight: "800",
          letterSpacing: "-.2rem",
        }}
      >
        WELCOME TO THE EXCHANGE CORNER IN THE WORLD OF WEB 3
      </p>

      <p className="wait__sub text-center col-12 col-md-7 p-0 m-0 mx-auto mt-5 mt-md-3">
        "Empower your financial future through the cryptographic revolution!"
      </p>

      <div className="mt-5 mt-md-3">
        <video
          autoPlay
          loop
          muted
          className="mx-auto text-center position-relative"
          style={{
            maxWidth: "760px",
            width: "100%",
            maxhHight: "440px",
            hight: "100%",
          }}
        >
          <source
            src={planetCrypto}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <button
        className="text-decoration-none text-dark border-0 d-flex fs-4 fw-semibold align-items-center px-4 rounded-3 my-5 my-md-4"
        style={{
          boxShadow: "4px 5px 10px rgba(0, 0, 0, 0.2)",
          height: "70px",
          backgroundColor: "#AEFC75",
          letterSpacing: "-.03rem",
        }}
        onClick={() => setShowModal(true)}
      >
        <span>Join the waitlist</span>

        <div className="d-none d-md-block">
          <img
            src={arrowRight}
            alt="arrowRight"
            style={{
              height: "40px",
            }}
          />
        </div>
      </button>

      <div
        className="d-none d-md-block position-absolute bottom-0"
        style={{
          zIndex: "-1",
          right: "-70px",
        }}
      >
        <img
          src={lineRight}
          alt="corner line right"
          style={{
            maxHeight: "25vh",
          }}
        />
      </div>

      <div
        className="d-none d-md-block position-absolute bottom-0"
        style={{
          zIndex: "-1",
          left: "-70px",
        }}
      >
        <img
          src={lineLeft}
          alt="corner line left"
          style={{
            maxHeight: "25vh",
          }}
        />
      </div>

      <ModalWait
        showModal={showModal}
        handleClose={handleClose}
        step={step}
        setStep={setStep}
      />
    </div>
  );
};
