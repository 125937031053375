import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICurrencySelect {
  title: string;
  subTitle: string;
  icon: string;
}

export interface IOrderSlice {
  inputAmount: string;
  inputValue: string;
  outputAmount: string;
  outputValue: string;
  inputSelect: ICurrencySelect[];
  outputSelect: ICurrencySelect[];
  listCryptocurrency: any;
}

const selectOut = [
  {
    title: "BTC",
    subTitle: "Bitcoin",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/btc.svg",
  },
  {
    title: "ETH",
    subTitle: "Ethereum",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/eth.svg",
  },
  {
    title: "USDT",
    subTitle: "Tether USD (Ethereum)",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/usdt.svg",
  },
  {
    title: "USDT",
    subTitle: "Tether USD (TRON)",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/usdt.svg",
  },
  {
    title: "BNB",
    subTitle: "Binance Coin (BSC)",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/bnb.svg",
  },
  {
    title: "XRP",
    subTitle: "Ripple",
    icon: "https://landing.fra1.digitaloceanspaces.com/svg/xrp.svg",
  },
];

const initialState = {
  inputAmount: "0.01",
  inputValue: "BTC",
  outputAmount: "0.1820865",
  outputValue: "ETH",
  inputSelect: selectOut,
  outputSelect: selectOut,
  listCryptocurrency: [],
};

type InputValue = PayloadAction<string>;
type InputAmount = PayloadAction<string>;
type OutputValue = PayloadAction<string>;
type OutputAmount = PayloadAction<string>;
type ListCryptocurrency = PayloadAction<any>;

const orderSlice = createSlice({
  name: "langIcon",
  initialState,
  reducers: {
    setInputValue: (state, action: InputValue) => {
      state.inputValue = action.payload;
    },
    setInputAmount: (state, action: InputAmount) => {
      state.inputAmount = action.payload;
    },
    setOutputValue: (state, action: OutputValue) => {
      state.outputValue = action.payload;
    },
    setOutputAmount: (state, action: OutputAmount) => {
      state.outputAmount = action.payload;
    },
    setListCryptocurrency: (state, action: ListCryptocurrency) => {
      state.listCryptocurrency = action.payload;
    },
  },
});

export const {
  setInputValue,
  setInputAmount,
  setOutputValue,
  setOutputAmount,
  setListCryptocurrency,
} = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
