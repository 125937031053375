import React from "react";
import { Modal, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import cornerLogoBlack from "../../static/icons/cornerLogoOnly.svg";
import crossModal from "../../static/icons/crossModal.svg";
import lineNavbar from "../../static/lineModalNavbar.svg";
import { Step0 } from "./Step0";
import { Step1 } from "./Step1";

interface IModalWaitProps {
  showModal: boolean;
  handleClose: () => void;
  step: number;
  setStep: (step: number) => void;
}

export const ModalWait: React.FC<IModalWaitProps> = ({
  showModal,
  handleClose,
  step,
  setStep,
}) => {
  return (
    <Modal
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={true}
    >
      <div className="container" style={{ zIndex: 3, background: "#fff" }}>
        <Navbar
          bg="transparent"
          expand="lg"
          className="wait__nav w-100 d-flex justify-content-end justify-content-md-between align-items-center"
        >
          <Link
            to="/"
            className="d-none d-md-flex text-decoration-none text-dark fw-bold align-items-center"
          >
            <img
              src={cornerLogoBlack}
              alt="cornerSwapLogo"
              style={{
                height: "100%",
              }}
            />

            <span
              className="fs-6"
              style={{
                fontWeight: "800",
              }}
            >
              CORNERSWAP
            </span>
          </Link>

          <button className="bg-transparent border-0" onClick={handleClose}>
            <img src={crossModal} alt="crossModal" height={48} />
          </button>
        </Navbar>
      </div>

      <div className="d-none d-md-block">
        <img src={lineNavbar} alt="lineNavbar" style={{ width: "100vw" }} />
      </div>

      {step === 0 && <Step0 setStep={setStep} />}

      {step === 1 && <Step1 />}
    </Modal>
  );
};
