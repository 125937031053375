import React from "react";
import Navbarr from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

export const Layout = ({ children }: any) => {
  return (
    <div
      className="d-flex flex-column justify-content-between w-100 m-0 p-0"
      style={{ minHeight: "100vh", height: "100%" }}
    >
      <Navbarr />

      <div className="navbar__height w-100 m-0 p-0"></div>

      <div
        className="mx-auto w-100 m-0 p-0"
        style={{ height: "100%", flex: "1" }}
      >
        {children}
      </div>

      <Footer />
    </div>
  );
};
