import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLangIcon, setLangValue } from "./redux/slices/language.slice";
import Home from "./pages/Home/Home";
import { Blog } from "./pages/Blog";
import { SingIn } from "./pages/SingIn";
import ArticleMain from "./components/Blog/ArticleMain";
import BlogList from "./components/Blog/BlogList";
import { Page404 } from "./pages/Page404"
import { WaitList } from "./pages/WaitList"

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const lang = localStorage.getItem("lng");
    const langIcon = localStorage.getItem("lngIcon");

    if (lang && langIcon && lang !== "en") {
      dispatch(setLangValue(lang));
      dispatch(setLangIcon(langIcon));
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<WaitList />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:categoryName" element={<BlogList />} />
        <Route path="/blog/:categoryName/:slug" element={<ArticleMain />} />

        <Route path="/singin" element={<SingIn />} />
        <Route path="/404" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
