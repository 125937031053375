import { combineReducers } from "redux";
import { ILangIcon, langIconReducer } from "../slices/language.slice";
import { IOrderSlice, orderReducer } from "../slices/order.slice";

export interface RootState {
  languageData: ILangIcon;
  orderData: IOrderSlice;
}

const rootReducer = combineReducers({
  languageData: langIconReducer,
  orderData: orderReducer,
});

export default rootReducer;
