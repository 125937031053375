import React, { useEffect, useState } from "react";
import cornerLogoBlack from "../../static/icons/cornerLogoBlack.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { Link } from "react-router-dom";
import { setListCryptocurrency } from "../../redux/slices/order.slice";
import axios from "axios";

const top10 = [
  {
    name: "Bitcoin",
    blockchain: "Bitcoin, Lightning",
    search: "BTC",
  },
  {
    name: "Ethereum ('Ether')",
    blockchain: "Ethereum (ERC-20)",
    search: "ETH",
  },
  {
    name: "Tether",
    blockchain: "Ethereum (ERC-20)",
    search: "USDT",
  },
  {
    name: " Binance Coin",
    blockchain: "Binance chain",
    search: "BNB",
  },
  {
    name: "Solana",
    blockchain: "Solana (SPL)",
    search: "SOL",
  },
  {
    name: "Ripple",
    blockchain: "BNB Smart Chain",
    search: "XRP",
  },
  {
    name: "US Dollar Coin",
    blockchain: "Ethereum (ERC-20)",
    search: "USDC",
  },
  {
    name: "Cardano",
    blockchain: "Cardano",
    search: "ADA",
  },
  {
    name: "Avalanche",
    blockchain: "C-Chain",
    search: "AVAX",
  },
  {
    name: "Dogecoin ",
    blockchain: "Ethereum (ERC-20)",
    search: "DOGE",
  },
];

const Footer = () => {
  const listCrypto = useSelector(
    ({ orderData }: RootState) => orderData.listCryptocurrency
  );

  const [category, setCategory] = useState(10);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [more, setMore] = useState(4);

  const dispatch = useDispatch();

  useEffect(() => {
    setMobileScreen(window.innerWidth < 768);

    const objectToPost = {
      input: "ZEUR",
    };
    const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

    axios
      .post(`${backendUrl}/pb/pairsByDevice`, objectToPost)
      .then(function (response: any) {
        if (response.status === 200) {
          dispatch(setListCryptocurrency(Object.values(response?.data)));
        }
      })
      .catch(function (error: any) {
        console.log("error :", error);
      });
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth < 768;

      screenWidth !== mobileScreen && setMobileScreen(screenWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    
  }, [mobileScreen]);

  const handleMore = () => {
    if (more + 2 < category) {
      setMore((prevState) => prevState + 2);
    } else {
      setMore(4);
    }
  };

  return (
    <div
      className="w-100 m-0 p-0"
      style={{ minHeight: "100vh", background: "rgba(22, 51, 0, .078)" }}
    >
      <div className="w-100 mx-auto px-2 py-5" style={{ maxWidth: "1320px" }}>
        <p className="fs-1 fw-bold m-0 pt-3">
          CornerSwap works nearly everywhere
        </p>

        <div className="mt-5">
          <button
            onClick={() => {
              setCategory(10);
              setMore(4);
            }}
            className={`${category === 10 && "navbarCont__button--green-dark"} fw-bold text-decoration-none py-2 px-4 text-center rounded-5 me-3`}
            style={{
              color: "#163300",
              border: "1px solid #163300",
            }}
          >
            Top 10
          </button>

          <button
            onClick={() => {
              setCategory(listCrypto.length);
              setMore(4);
            }}
            className={`${category === listCrypto.length && "navbarCont__button--green-dark"} fw-bold text-decoration-none py-2 px-4 text-center rounded-5`}
            style={{
              color: "#163300",
              border: "1px solid #163300",
            }}
          >
            All Cryptocurrency
          </button>
        </div>

        <div className="row d-flex justify-content-between flex-wrap m-0 mt-2">
          {category === 10 && top10
              .slice(0, mobileScreen ? more : top10.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p className="m-0 fw-bold mt-3" style={{ color: "#5D7079" }}>
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: "#5D7079" }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link to="/" className="m-0 fw-bold text-dark">
                    Get {cryptoInfo?.search} account details
                  </Link>
                </div>
              ))}

          {category === listCrypto.length && listCrypto
              .slice(0, mobileScreen ? more : listCrypto.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p className="m-0 fw-bold mt-3" style={{ color: "#5D7079" }}>
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: "#5D7079" }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link to="/" className="m-0 fw-bold text-dark">
                    Get {cryptoInfo?.search} account details
                  </Link>
                </div>
              ))}

          {mobileScreen && (
            <button
              onClick={handleMore}
              className="mt-5 border-0 bg-transparent fw-bold text-decoration-underline"
            >
              {more < category ? "Show more" : "Show less"}
            </button>
          )}
        </div>

        <div
          className="w-100 rounded-5 px-5 py-5"
          style={{ background: "#fff", marginTop: "100px", color: "#5D7079" }}
        >
          <p>
            Annual Percentage Yield (APY) - rates shown are true as of
            01/01/2024. See Program Agreement for details.
          </p>

          <p>
            The current Program Bank is JPMorgan Chase Bank, N.A., see Appendix
            1 of the Program Agreement for the most updated list of Program
            Bank(s). Eligible customers must opt in to the interest feature.
            Participants will have the balance of their USD funds held in their
            Wise Account “swept” into a Federal Deposit Insurance Corporation
            (“FDIC”) insured interest-bearing account at one or more
            participating banks (each, a “Program Bank”) that will hold and pay
            interest on the deposit funds. For a complete list of Program Banks,
            please see Appendix 1 of the Program Agreement. For more information
            on FDIC insurance coverage, please visit. Customers are responsible
            for monitoring their total assets at each of the Program Banks to
            determine the extent of available FDIC insurance coverage in
            accordance with FDIC rules. The Program is not intended to be a
            long-term investment option, checking or savings account, investment
            contract or security.
          </p>
          <p>
            {" "}
            For customers opted in to receive interest on EUR and GBP balances,
            the FDIC passthrough insurance provided by our program bank is for
            up to the equivalent of $250,000 in total for your USD, EUR and GBP
            combined balance amounts (collectively, the “Eligible Balances”).
          </p>
        </div>

        <div
          className="w-100 px-5 py-5 row justify-content-center mx-auto"
          style={{ marginTop: "100px", borderBottom: "1px solid #6c757d" }}
        >
          <div className="d-md-none text-center mb-5">
            <img
              src={cornerLogoBlack}
              alt="cornerLogoBlack"
              style={{
                height: "60px",
              }}
            />
          </div>

          <div className="col-12 col-md-3 d-flex flex-column align-items-center align-items-md-start">
            <p className="m-0 fw-bold mb-3">Company and team</p>

            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Company and team
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              List
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Roadmap
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Team
            </Link>
          </div>

          <div className="col-12 col-md-3 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">Company and team</p>

            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Company and team
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              List
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Roadmap
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Team
            </Link>
          </div>

          <div className="col-12 col-md-3 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">Company and team</p>

            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Company and team
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              List
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Roadmap
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Price
            </Link>
            <Link to="/" className="m-0" style={{ color: "#5d7079" }}>
              Team
            </Link>
          </div>

          <div className="col-12 col-md-3 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">Follow us</p>

            <div className="d-flex">
              <Link
                to="/"
                className="me-3 d-flex text-dark"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 1.715C6.3 1.715 1.714 6.3 1.714 12c0 5.142 3.772 9.385 8.657 10.157v-7.2H7.757V12h2.614V9.729c0-2.571 1.543-3.986 3.9-3.986 1.115 0 2.315.215 2.315.215v2.528H15.3c-1.286 0-1.671.772-1.671 1.586v1.929h2.87l-.47 2.957h-2.4v7.2c4.885-.772 8.657-5.015 8.657-10.157C22.286 6.3 17.7 1.715 12 1.715Z"></path>
                </svg>
              </Link>

              <Link
                to="/"
                className="me-3 d-flex text-dark"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.186 7.995v.557c0 5.572-4.243 12-12 12-2.315 0-4.543-.643-6.472-1.886.343.043.686.043.986.043a8.585 8.585 0 0 0 5.229-1.8 4.238 4.238 0 0 1-3.943-2.914 3.992 3.992 0 0 0 1.885-.086c-1.971-.385-3.385-2.143-3.385-4.157V9.71c.6.343 1.243.515 1.928.515C2.571 8.98 1.971 6.538 3.13 4.609a11.937 11.937 0 0 0 8.7 4.415c-.343-1.458.128-3 1.2-4.029 1.714-1.586 4.37-1.5 5.957.171a7.996 7.996 0 0 0 2.7-1.028c-.3.986-.986 1.8-1.843 2.314.857-.086 1.671-.343 2.443-.643-.558.857-1.286 1.586-2.1 2.186Z"></path>
                </svg>
              </Link>

              <Link
                to="/"
                className="me-3 d-flex text-dark"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 3.558c2.743 0 3.086 0 4.157.043.986.042 1.543.214 1.929.342.471.172.814.429 1.2.772.342.343.6.686.771 1.2.129.343.3.9.343 1.928.043 1.072.043 1.415.043 4.158 0 2.742 0 3.085-.043 4.157-.043.985-.214 1.543-.343 1.928-.171.472-.428.815-.771 1.2-.343.343-.686.6-1.2.772-.343.128-.9.3-1.929.343-1.071.042-1.414.042-4.157.042-2.743 0-3.086 0-4.157-.042-.986-.043-1.543-.215-1.929-.343-.471-.172-.814-.429-1.2-.772-.343-.343-.6-.685-.771-1.2-.129-.343-.3-.9-.343-1.928-.043-1.072-.043-1.415-.043-4.157 0-2.743 0-3.086.043-4.158.043-.985.214-1.542.343-1.928.171-.472.428-.814.771-1.2.343-.343.686-.6 1.2-.772.343-.128.9-.3 1.929-.342 1.071-.043 1.414-.043 4.157-.043Zm0-1.843c-2.786 0-3.129 0-4.243.043-1.114.043-1.843.214-2.486.471-.685.257-1.242.6-1.842 1.2a5.072 5.072 0 0 0-1.2 1.843c-.258.643-.43 1.414-.472 2.486-.043 1.114-.043 1.457-.043 4.243 0 2.785 0 3.128.043 4.242.043 1.115.214 1.843.472 2.486.257.686.6 1.243 1.2 1.843a5.073 5.073 0 0 0 1.842 1.2c.643.257 1.415.429 2.486.471 1.114.043 1.457.043 4.243.043 2.786 0 3.129 0 4.243-.043 1.114-.042 1.843-.214 2.486-.471.685-.257 1.242-.6 1.842-1.2a5.074 5.074 0 0 0 1.2-1.843c.258-.643.429-1.414.472-2.486.043-1.114.043-1.457.043-4.242 0-2.786 0-3.129-.043-4.243-.043-1.115-.214-1.843-.472-2.486-.257-.686-.6-1.243-1.2-1.843a5.072 5.072 0 0 0-1.843-1.2c-.642-.257-1.414-.428-2.485-.471-1.114-.043-1.457-.043-4.243-.043Zm0 5.014a5.268 5.268 0 0 0-5.271 5.27 5.268 5.268 0 0 0 5.27 5.272A5.268 5.268 0 0 0 17.272 12 5.268 5.268 0 0 0 12 6.729Zm0 8.7a3.439 3.439 0 0 1-3.429-3.43A3.439 3.439 0 0 1 12 8.572 3.439 3.439 0 0 1 15.428 12 3.439 3.439 0 0 1 12 15.428Zm6.729-8.915a1.243 1.243 0 1 1-2.486 0 1.243 1.243 0 0 1 2.486 0Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-100 px-5 py-5 row justify-content-center">
          <div className="d-none d-md-flex col-md-3">
            <img
              src={cornerLogoBlack}
              alt="cornerLogoBlack"
              style={{
                height: "50px",
              }}
            />
          </div>

          <div className="col-12 col-md-9">
            <div className="row">
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Company and team
              </Link>
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Price
              </Link>
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Price
              </Link>
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Company and team
              </Link>
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Price
              </Link>
              <Link
                to="/"
                className="m-0 col-6 col-md-4 text-center text-md-start"
                style={{ color: "#5d7079" }}
              >
                Price
              </Link>
            </div>
          </div>
        </div>

        <p className="mx-auto text-center" style={{ color: "#5D7079" }}>
          © CornerSwap Inc 2024
        </p>

        <p
          className="mx-auto text-center col-12 col-md-8"
          style={{ color: "#5D7079" }}
        >
          CornerSwap is a Money Service Business registered with FinCen. It is
          authorized to operate in most states. In other states, the program is
          sponsored by Community Federal Savings Bank, to which we’re a service
          provider.
        </p>
      </div>
    </div>
  );
};

export default Footer;
