import React from "react";
import { Layout } from "../components/Layout";

export const SingIn = () => (
  <Layout>
    <div
      className="text-dark fs-1 fw-bold d-flex justify-content-center"
      style={{ height: "80vh" }}
    >
      <form
        onSubmit={(e) => e.preventDefault()}
        className="w-25 d-flex h-100 flex-column justify-content-center"
      >
        <input
          type="text"
          id="email"
          name="email"
          className="form-control py-3 px-4 my-3 rounded"
          placeholder="Enter your email"
          aria-label="Recipient's email"
          aria-describedby="button-addon2"
          autoComplete="off"
        />
        <input
          type="password"
          id="password"
          name="email"
          className="form-control py-3 px-4 my-3 rounded"
          placeholder="Enter your password"
          aria-label="Recipient's password"
          aria-describedby="button-addon2"
          autoComplete="off"
        />
        <button
          className="btn my-3 px-5 btn-success rounded fw-bold"
          type="submit"
        >
          Sing In
        </button>
      </form>
    </div>
  </Layout>
);
