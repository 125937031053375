import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LanguagesBar } from "./LanguagesBar";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import cornerLogoBlack from "../../static/icons/cornerLogoBlack.png";

const Navbarr = () => {
  const [drawerToggle, setDrawerToggle] = useState(false);

  const abbrLang = localStorage.getItem("lng");

  const { t } = useTranslation();

  return (
    <div
      className={`navbarCont navbar__height d-flex position-fixed ${drawerToggle && "vh-100 position-fixed"}`}
      style={{
        zIndex: 1000,
        backgroundColor: "white",
        width: "100%",
      }}
    >
      <div
        className="d-flex px-lg-3 w-100 mx-auto navbar__height"
        style={{ color: "#2b2b36", maxWidth: "1320px" }}
      >
        <div className="d-none d-md-flex justify-content-between align-items-center w-100">
          <Link to="/">
            <img
              src={cornerLogoBlack}
              alt="cornerSwapLogo"
              style={{
                height: "50px",
              }}
            />
          </Link>

          <Navbar className="d-flex align-items-center w-100">
            <Nav className="text-start me-3 w-100 d-flex align-items-center">
              <Link
                to="/"
                className="d-none d-md-flex ms-4 navbarCont__button--green fw-bold text-dark text-decoration-none px-3 py-1 text-center rounded-5"
              >
                Personal
              </Link>
              <Link
                to="/"
                className="d-none d-md-flex navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 ps-3 text-center rounded-5"
              >
                Business
              </Link>
              <Link
                to="/"
                className="d-none d-md-flex navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 ps-3 text-center rounded-5"
              >
                ON-Ramp
              </Link>
            </Nav>
            <Nav className="d-flex justify-content-end w-100 h-100">
              <Link
                to="/blog"
                className="ms-2 navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 px-3 text-center rounded-5"
              >
                {t("navBar.blog")}
              </Link>
              <div className="d-flex fw-bold align-items-center">
                <LanguagesBar
                  classProp={
                    "p-0 pb-1 text-dark drop-language d-flex justify-content-end align-items-center"
                  }
                />
                EN
              </div>
              <Link
                className="ms-2 navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 px-2 px-lg-3 text-center rounded-5"
                to="/"
              >
                {t("navBar.signIn")}
              </Link>
              <Link
                to="/"
                className="ms-2 navbarCont__button--green fw-bold text-dark text-decoration-none py-1 px-3 text-center rounded-5"
              >
                Register
              </Link>
            </Nav>
          </Navbar>
        </div>

        <div
          className="d-flex d-md-none w-100 justify-content-between align-items-center border-bottom"
          style={{
            padding: "32px",
          }}
        >
          <Link style={{
                height: "30px",
              }} to="/">
            <img
              src={cornerLogoBlack}
              alt="cornerSwapLogo"
              style={{
                height: "50px",
              }}
            />
          </Link>

          <div
            className={`d-md-none navbarCont1MobileBtn ${drawerToggle && "open"}`}
            onClick={() => setDrawerToggle(!drawerToggle)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div
        className={
          drawerToggle
            ? "w-100 drawerNav d-flex align-items-start vh-100 d-md-none"
            : "d-none"
        }
        style={{
          paddingTop: "37px",
        }}
      >
        <Link to="/">
          <img
            src={cornerLogoBlack}
            alt="cornerSwapLogo"
            style={{
              height: "50px",
              paddingLeft: "32px",
            }}
          />
        </Link>

        <div
          className="border-bottom w-100"
          style={{
            marginTop: "37px",
          }}
        ></div>

        <Navbar className="d-flex flex-column align-items-start p-4 w-100">
          <Nav className="">
            <Link
              to="/"
              className="d-flex navbarCont__button--green fw-bold text-dark text-decoration-none px-3 py-1 text-center rounded-5"
            >
              Personal
            </Link>
            <Link
              to="/"
              className="d-flex navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 px-3 text-center rounded-5"
            >
              Business
            </Link>
          </Nav>{" "}
          <Link
            to="/"
            className="pt-4 d-flex navbarCont__button--silver fw-bold text-dark text-decoration-none px-3 py-1 text-center rounded-5"
          >
            Pricing
          </Link>
          <Link
            to="/"
            className="pt-4 d-flex navbarCont__button--silver fw-bold text-dark text-decoration-none py-1 px-3 pb-2 text-center rounded-5"
          >
            Help
          </Link>
          <div className="d-flex w-100 justify-content-between pt-4 py-1 px-3">
            <LanguagesBar classProp="" />
            <p className="m-0 p-0 fw-bold">{abbrLang?.toLocaleUpperCase()}</p>
          </div>
        </Navbar>

        <div className="w-100 d-flex flex-column align-items-center mt-auto mb-5 px-2">
          <Link
            className="w-100 d-flex justify-content-center navbarCont__button--green fw-bold text-dark text-decoration-none py-2 text-center rounded-5"
            to="/"
          >
            {t("navBar.signIn")}
          </Link>
          <Link
            className="w-100 d-flex justify-content-center navbarCont__button--silver fw-bold text-dark text-decoration-none py-2 text-center rounded-5 mt-3"
            to="/"
          >
            {t("navBar.signUp")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbarr;
