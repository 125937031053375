import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IArticle } from "../../pages/Blog";
import { Layout } from "../Layout";
import { useTranslation } from "react-i18next"

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const BlogList = () => {
  const { categoryName } = useParams();
  
  const { t } = useTranslation();

  const [articlesList, setArticlesList] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${backendUrl}/articles`)
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter(
          (article: IArticle) =>
            article.category.split(" ").join("-") === categoryName
        );

        setArticlesList(filteredData);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleConvertDate = (value: string) => {
    const [year, month, day] = value.split(" ")[0].split("-");

    const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`);

    interface IOptions {
      day: "numeric" | "2-digit" | undefined;
      month: "numeric" | "2-digit" | "short" | "long" | "narrow" | undefined;
      year: "numeric" | "2-digit" | undefined;
    }

    const options: IOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);
    const formattedString = dateFormatter.format(formattedDate);

    return formattedString;
  };

  return (
    <Layout>
      {loading ? (
        <div className="d-flex w-100">
          <div
            className="spinner-border my-5 mx-auto"
            role="status"
            style={{
              height: "100px",
              width: "100px",
            }}
          ></div>
        </div>
      ) : (
        <div className="container pb-5">
          <h1 className="text-center mt-5 fw-bold">
            {categoryName?.split("-").join(" ")}
          </h1>

          <h3 className="text-center mt-1">
            {t("blog.listTitle")}{" "}{categoryName?.split("-").join(" ")}
          </h3>

          <div className="col-12 d-flex flex-wrap p-0 mt-5">
            {articlesList?.map((article: any, index: number) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-4 pe-sm-3 pb-3 pt-0 ps-0"
                key={index}
              >
                <div className="card h-100">
                  <div className="text-dark h-100 text-decoration-none d-flex flex-column justify-content-between">
                    <div>
                      <a
                        href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                      >
                        <img
                          src={article.picture}
                          alt={article.altPicture}
                          className="mb-3 rounded-top-2"
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </a>

                      <div className="px-3 pb-3">
                        <div className="my-3 d-flex justify-content-between text-dark">
                          <p className="m-0 text-success fs-6">
                            {article?.category}
                          </p>

                          <p className="m-0">
                            {handleConvertDate(
                              article.releaseDate.split(" ")[0]
                            )}
                          </p>
                        </div>

                        <a
                          href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                          className="mt-3 fs-6 fw-bold text-dark text-decoration-none blog-article--hover"
                        >
                          {article.title}
                        </a>
                        <p
                          className="mt-3 fs-6"
                          dangerouslySetInnerHTML={{
                            __html: article.short_description,
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="px-3 pb-3">
                      <img
                        src={article?.urlPhoto}
                        alt="avatar"
                        className="rounded-circle"
                        width="30"
                        height="30"
                      />
                      <span className="m-0 ms-3 fw-bold">
                        {article?.name} {article?.firstName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default BlogList;
