import React, { useEffect, useState } from "react";
import { Layout } from "../Layout";
import { useParams } from "react-router-dom";
import { IArticle } from "../../pages/Blog";
import { useTranslation } from "react-i18next";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const ArticleMain = () => {
  const { t } = useTranslation();

  const { slug } = useParams();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState<IArticle>();
  const [lastArticle, setLastArticle] = useState<IArticle[]>();

  useEffect(() => {
    fetch(`${backendUrl}/articles/articleBySlug/${slug}`)
      .then((res) => res.json())
      .then((data) => {
        setArticle(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    fetch(`${backendUrl}/articles`)
      .then((res) => res.json())
      .then((data) => {
        setLastArticle(data.slice(0, 3));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleConvertDate = (value: string) => {
    const [year, month, day] = value.split(" ")[0].split("-");

    const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`);

    interface IOptions {
      day: "numeric" | "2-digit" | undefined;
      month: "numeric" | "2-digit" | "short" | "long" | "narrow" | undefined;
      year: "numeric" | "2-digit" | undefined;
    }

    const options: IOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);
    const formattedString = dateFormatter.format(formattedDate);

    return formattedString;
  };

  return (
    <Layout>
      {loading ? (
        <div className="d-flex w-100 mt-5">
          <div
            className="spinner-border my-5 mx-auto"
            role="status"
            style={{
              height: "150px",
              width: "150px",
            }}
          ></div>
        </div>
      ) : (
        <>
          <div className="container my-5">
            {article && (
              <div className="row">
                <div className="col-12 col-lg-11 d-md-flex mx-auto">
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                    <div className="d-flex align-items-center mb-4">
                      <a
                        href="/blog"
                        className="fw-bold fs-4 me-2 text-secondary text-decoration-none"
                      >
                        Blog
                      </a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        width="18px"
                        height="18px"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        ></path>
                      </svg>
                      <a
                        href={`/blog/${article?.category.split(" ").join("-")}`}
                        className="fw-bold fs-4 ms-2 text-success text-decoration-none"
                      >
                        {article?.category}
                      </a>
                    </div>

                    <span className="fw-bold fs-1">{article?.title}</span>

                    <p
                      className="m-0 mt-3 fs-5"
                      dangerouslySetInnerHTML={{
                        __html: article.short_description,
                      }}
                    ></p>

                    <div className="cols-sm-12 col-lg-8 text-secondary-emphasis pt-2 d-flex justify-content-between">
                      <span>{handleConvertDate(article?.createdAt ?? "")}</span>

                      <a
                        href={`/blog/${article?.category.split(" ").join("-")}`}
                        className="text-dark text-decoration-none bg-light px-2 fw-bold d-flex align-items-center"
                      >
                        <span
                          style={{ fontSize: "0.7em", marginRight: "0.5em" }}
                        >
                          •
                        </span>
                        <span className="blog-article--hover">
                          {article?.category}
                        </span>
                      </a>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <div className="d-flex align-items-center">
                        <img
                          src={article?.urlPhoto}
                          alt="avatar"
                          className="rounded-circle"
                          width="60"
                          height="60"
                        />

                        <div className="ms-3">
                          <span className="mb-0 d-flex align-items-center fw-bold fs-5 text-success">
                            {article?.firstName} {article?.name}
                          </span>
                          <span>Content Writer @ Corner Swap</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mt-5 mt-md-0 justify-content-center justify-content-md-end">
                    <img
                      src={article?.picture}
                      alt="articlePicture"
                      style={{ width: "80%", objectFit: "cover" }}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-8 mx-auto mt-5">
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  ></div>
                </div>
              </div>
            )}
          </div>

          <div className="w-100 py-5" style={{ background: "#c2f3a2" }}>
            <p className="w-100 text-center fw-bold fs-3 mx-auto pb-3">
              {t("blog.latestArticles")}
            </p>

            <div className="container col-12 d-flex flex-wrap px-2 px-sm-0 p-0 pb-5">
              {lastArticle?.map((article: any, index: number) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-4 pe-sm-3 pb-3 pt-0 ps-0"
                  key={index}
                >
                  <div className="card h-100">
                    <div className="text-dark h-100 text-decoration-none d-flex flex-column justify-content-between">
                      <div>
                        <a
                          href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                        >
                          <img
                            src={article.picture}
                            alt={article.altPicture}
                            className="mb-3 rounded-top-2"
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </a>

                        <div className="px-3 pb-3">
                          <div className="my-3 d-flex justify-content-between text-dark">
                            <a
                              href={`/blog/${article.category.split(" ").join("-")}`}
                              className="m-0 text-success fs-6 blog-article--hover text-decoration-none"
                            >
                              {article?.category}
                            </a>
                            <p className="m-0">
                              {handleConvertDate(
                                article.releaseDate.split(" ")[0]
                              )}
                            </p>
                          </div>

                          <a
                            href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                            className="mt-3 fs-6 fw-bold text-dark text-decoration-none blog-article--hover"
                          >
                            {article.title}
                          </a>
                          <p
                            className="mt-3 fs-6"
                            dangerouslySetInnerHTML={{
                              __html: article.short_description,
                            }}
                          ></p>
                        </div>
                      </div>

                      <div className="px-3 pb-3">
                        <img
                          src={article?.urlPhoto}
                          alt="avatar"
                          className="rounded-circle"
                          width="30"
                          height="30"
                        />
                        <span className="m-0 ms-3 fw-bold">
                          {article?.name} {article?.firstName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ArticleMain;
