import React, { useEffect, useState, useTransition } from "react";
import { Layout } from "../components/Layout";
import { Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

interface ICategory {
  id: number;
  name: string;
  categoryName: string | undefined;
}

export interface IArticle {
  picture: string;
  altPicture: string;
  title: string;
  category: string;
  slug: string;
  headMetaContent: string;
  headMetaKey: string;
  headMetaName: string;
  headTitle: string;
  aLaUne: number;
  mostRead: number;
  isActive: number;
  isSEOValidate: number;
  authorID: number;
  createdAt: string;
  releaseDate: string;
  id: number;
  name: string;
  firstName: string;
  descriptionAuthor: string;
  linkFacebook: string;
  linkLinkedin: string;
  urlPhoto: string;
  short_description: string;
  description: string;
}

export const Blog = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState<IArticle[] | []>([]);
  const [filteredArticles, setFilteredArticles] = useState<IArticle[] | []>([]);
  const [selectedCategory, setSelectedCategory] = useState("Latest");

  useEffect(() => {
    handleData();

    setLoading(false);
  }, []);

  useEffect(() => {
    setFilteredArticles(
      selectedCategory
        ? selectedCategory === "Latest"
          ? articles
          : articles.filter(
              (article: IArticle) => article.category === selectedCategory
            )
        : articles
    );
  }, [selectedCategory]);

  const handleData = async () => {
    setLoading(true);

    await fetch(`${backendUrl}/categories`)
      .then((res) => res.json())
      .then((data) => setCategories(data))
      .catch((err) => console.log("Error fetch categories: ", err));

    await fetch(`${backendUrl}/articles`)
      .then((res) => res.json())
      .then((data) => {
        setArticles(data);
        setFilteredArticles(data);
      })
      .catch((err) => console.log("Error fetch articles: ", err));
  };

  const handleConvertDate = (value: string) => {
    const [year, month, day] = value.split(" ")[0].split("-");

    const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`);

    interface IOptions {
      day: "numeric" | "2-digit" | undefined;
      month: "numeric" | "2-digit" | "short" | "long" | "narrow" | undefined;
      year: "numeric" | "2-digit" | undefined;
    }

    const options: IOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);
    const formattedString = dateFormatter.format(formattedDate);

    return formattedString;
  };

  return (
    <Layout>
      {loading ? (
        <div className="d-flex w-100">
          <div
            className="spinner-border my-5 mx-auto"
            role="status"
            style={{
              height: "100px",
              width: "100px",
            }}
          ></div>
        </div>
      ) : (
        <div className="container d-flex flex-column h-100">
          {articles[0] && (
            <div className="row pt-5">
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start mb-4 mb-lg-0">
                <img
                  src={articles[0]?.picture}
                  alt="articlePicture"
                  style={{
                    width: "80%",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="col-12 col-md-6">
                <div className="d-flex align-items-center mb-4">
                  <span className="fw-bold fs-4 me-2 text-secondary">Blog</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    width="18px"
                    height="18px"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    ></path>
                  </svg>
                  <a
                    href={`/blog/${articles[0].category.split(" ").join("-")}`}
                    className="fw-bold fs-4 ms-2 text-success text-decoration-none"
                  >
                    {articles[0]?.category}
                  </a>
                </div>

                <a
                  href={`/blog/${articles[0].category.split(" ").join("-")}/${articles[0].slug}`}
                  className="fw-bold fs-1 text-dark text-decoration-none blog-article--hover"
                >
                  {articles[0]?.title}
                </a>

                <p
                  className="m-0 mt-3 fs-5"
                  dangerouslySetInnerHTML={{
                    __html: articles[0]?.short_description,
                  }}
                ></p>

                <div className="cols-sm-12 col-lg-8 text-secondary-emphasis pt-2 d-flex justify-content-between">
                  <span>{handleConvertDate(articles[0]?.createdAt)}</span>

                  <a
                    href={`/blog/${articles[0].category.split(" ").join("-")}`}
                    className="text-dark text-decoration-none bg-light px-2 fw-bold d-flex align-items-center"
                  >
                    <span style={{ fontSize: "0.7em", marginRight: "0.5em" }}>
                      •
                    </span>
                    <span className="blog-article--hover">
                      {articles[0].category}
                    </span>
                  </a>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={articles[0]?.urlPhoto}
                      alt="avatar"
                      className="rounded-circle"
                      width="60"
                      height="60"
                    />

                    <div className="ms-3">
                      <span className="mb-0 d-flex align-items-center fw-bold fs-5 text-success">
                        {articles[0]?.firstName} {articles[0]?.name}
                      </span>
                      <span>{t("blog.authorSub")} @ Corner Swap</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Navbar bg="white" className="mx-auto rounded-3 w-100 mt-4">
            <div className="container d-flex flex-column px-3">
              <div
                className="col-12 d-flex justify-content-between align-items-center my-4"
                style={{ overflowX: "auto" }}
              >
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    <button
                      className={`bg-transparent border-0 text-black fs-5 mx-3 me-5 text-bolder text-decoration-none ${selectedCategory === "Latest" && "border-bottom border-dark fw-bold"}`}
                      style={{ width: "max-content" }}
                      onClick={() => setSelectedCategory("Latest")}
                    >
                      Latest
                    </button>

                    {categories?.map((category: ICategory) => (
                      <button
                        className={`bg-transparent border-0 text-black fs-5 mx-3 me-5 text-bolder text-decoration-none ${selectedCategory === category.name && "border-bottom border-dark fw-bold"}`}
                        style={{ width: "max-content" }}
                        onClick={() => setSelectedCategory(category.name)}
                        key={category.id}
                      >
                        {category.name}
                      </button>
                    ))}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </Navbar>

          <div className="col-12 d-flex flex-wrap p-0">
            {filteredArticles?.map((article: any, index: number) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-4 pe-sm-3 pb-3 pt-0 ps-0"
                key={index}
              >
                <div className="card h-100">
                  <div className="text-dark h-100 text-decoration-none d-flex flex-column justify-content-between">
                    <div>
                      <a
                        href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                      >
                        <img
                          src={article.picture}
                          alt={article.altPicture}
                          className="mb-3 rounded-top-2"
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </a>

                      <div className="px-3 pb-3">
                        <div className="my-3 d-flex justify-content-between text-dark">
                          <a
                            href={`/blog/${article.category.split(" ").join("-")}`}
                            className="m-0 text-success fs-6 blog-article--hover text-decoration-none"
                          >
                            {article?.category}
                          </a>
                          <p className="m-0">
                            {handleConvertDate(
                              article.releaseDate.split(" ")[0]
                            )}
                          </p>
                        </div>

                        <a
                          href={`/blog/${article.category.split(" ").join("-")}/${article.slug}`}
                          className="mt-3 fs-6 fw-bold text-dark text-decoration-none blog-article--hover"
                        >
                          {article.title}
                        </a>
                        <p
                          className="mt-3 fs-6"
                          dangerouslySetInnerHTML={{
                            __html: article.short_description,
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="px-3 pb-3">
                      <img
                        src={article?.urlPhoto}
                        alt="avatar"
                        className="rounded-circle"
                        width="30"
                        height="30"
                      />
                      <span className="m-0 ms-3 fw-bold">
                        {article?.name} {article?.firstName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="input-group d-flex p-sm-4 mt-5 justify-content-center rounded-2 rounded-2">
            <p className="text-dark fs-1 fw-bold mt-1 d-flex justify-content-center mx-auto col-12">
              {t("blog.subscribe.title")}
            </p>

            <p className="text-dark fs-5 mt-1 d-flex justify-content-center col-11 col-md-8 col-lg-6">
              {t("blog.subscribe.sub")}
            </p>

            <div className="d-flex col-12 col-lg-7 mb-5">
              <input
                type="email"
                className="form-control py-3 my-3 rounded"
                placeholder={t("blog.subscribe.placeholder")}
                aria-label="Recipient's email"
                aria-describedby="button-addon2"
              />
              <button
                className="btn bg-success text-light my-3 px-5 btn-light ms-2 rounded fw-bold"
                type="button"
                id="button-addon2"
              >
                {t("blog.subscribe.button")}
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
