import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";
import { createStore } from "redux";
import rootReducer from "./redux/store/store";
import { Provider } from "react-redux";

const store = createStore(rootReducer);

const rootDocument = document.getElementById("root");

if (rootDocument) {
  const root = ReactDOM.createRoot(rootDocument);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  );
}
