import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ILangIcon {
  lngIcon: string;
  lngValue: string;
}

const initialState: ILangIcon = {
  lngIcon: "https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg",
  lngValue: "en",
};

type LanguageIcon = PayloadAction<string>;
type LanguageValue = PayloadAction<string>;

const langIconSlice = createSlice({
  name: "langIcon",
  initialState,
  reducers: {
    setLangIcon: (state, action: LanguageIcon) => {
      state.lngIcon = action.payload;
    },
    setLangValue: (state, action: LanguageValue) => {
      state.lngValue = action.payload;
    },
  },
});

export const { setLangIcon, setLangValue } = langIconSlice.actions;
export const langIconReducer = langIconSlice.reducer;