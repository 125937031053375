import React from "react";
import lineRight from "../../static/lineRight.svg";
import lineLeft from "../../static/lineLeft.svg";
import lineStep1 from "../../static/lineStep1.svg";

export const Step1 = () => (
  <div className="container position-relative d-flex h-100">
    <div
      className="my-auto d-flex flex-column justify-content-center align-items-center"
      style={{ 
        height: "440px", 
      zIndex: 1, background: "#fff" }}
    >
      <p
        className="text-center col-9 col-md-12 wait__title lh-sm"
        style={{
          fontWeight: "800",
          letterSpacing: "-0.2rem",
        }}
      >
        Thank you, you will be notified by email !
      </p>

      <img
        src={lineStep1}
        alt="lineStep1"
        style={{
          maxWidth: "705px",
          width: "100%",
        }}
      />
    </div>

    <div
      className="d-none d-md-block position-absolute bottom-0"
      style={{
        right: "0",
      }}
    >
      <img
        src={lineRight}
        alt="corner line right"
        style={{
          maxHeight: "25vh",
        }}
      />
    </div>

    <div
      className="d-none d-md-block position-absolute bottom-0"
    >
      <img
        src={lineLeft}
        alt="corner line left"
        style={{
          maxHeight: "25vh",
        }}
      />
    </div>
  </div>
);
